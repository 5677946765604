import { useState, useEffect } from 'react';
import { Table, Spin, Badge, Select, Space, Input, Typography } from 'antd';
import { useNavigate } from 'react-router';
import Button from '../../../../../components/Button';
import axios from 'axios';
import ActiveSwitch from '../activeChecker';
import DropdownActions from '../dropdownActions';
import useDebouncedOnChange from '../../../../../functions/useDebouncedOnChange';
import {
  SearchOutlined
} from '@ant-design/icons';
import ActiveSwitchBlock from '../activeBlocker';
const {Text} = Typography

const UserTable = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    search: "",
    filters: {}
  });
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);
  const setSearch = (value) => {
    setPagination({...pagination, search: value})
  }
  const debouncedOnChange = useDebouncedOnChange(setSearch);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/auth/get/user');
        setUser(response.data)
      } catch (error) {
        console.error('Errord fetchsing sdata:', error);
      }
    };

    fetchData();
  }, [])

  const fetchData = async (page, pageSize, search, filters) => {
    try {
      setLoading(true);
      // console.log(search)
      // Replace 'yourApiEndpoint' with your actual server API endpoint
      const response = await axios.post('/users/list', {
        pagination: { ...pagination, current: page, pageSize: pageSize, search: search, filters: filters },
      });

      setData(response.data.rows);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize, pagination.search, pagination.filters);
  }, [pagination.current, pagination.pageSize, pagination.search, reload, pagination.filters]);

  const handleTableChange = (pagination, filter) => {
    // console.log({...pagination, filters: filter})
    setPagination({...pagination, filters: filter});
  };

  const handlePageSizeChange = (value) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to the first page when changing page size
    });
  };

  const columns = [
    // Define your table columns here
    {
      title: 'ID',
      dataIndex: 'mlg_users_id',
      key: 'mlg_users_id',
    },
    {
      title: 'Imię i nazwisko',
      dataIndex: 'mlg_users_fullname',
      key: 'mlg_users_fullname',
    },
    {
      title: 'Status sesji',
      dataIndex: 'active_session',
      key: 'active_session',
      render: (item, record) => {
        return <Badge style={{minWidth: "100px"}} status={item ? 'success' : 'error'} text={item ? 'Aktywny' : 'Nieaktywny'} />
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Numer Telefonu',
      dataIndex: 'phone',
      key: 'phone',
      render: (item) => {
        return item === null ? "---" : item
      }
    },
    {
      title: 'Aktywny',
      filters: [
        { text: 'Aktywny', value: true },
        { text: 'Nieaktywny', value: false }
      ],
      dataIndex: 'mlg_users_active',
      key: 'mlg_users_active',
      render: (item, record) => {
        return <ActiveSwitch value={item} id={record.mlg_users_id} />;
      },
    },
    {
      title: 'Zablokowany',
      dataIndex: 'mlg_users_blocked',
      key: 'mlg_users_blocked',
      render: (item, record) => {
        return <ActiveSwitchBlock value={item} id={record.mlg_users_id} />;
      },
    },
    {
      title: 'Trwała blokada / usunięcie',
      dataIndex: 'mlg_users_deleted',
      key: 'mlg_users_deleted',
      render: (item, record) => {
        return <>{item == 1 ? <Text type="danger">Tak</Text> : "Nie"}</>;
      },
    },
    {
      title: 'Akcje',
      dataIndex: 'mlg_users_id',
      key: 'mlg_users_id',
      width: 150,
      render: (item, record) => {
        return (
          <>
          {user != null ? <DropdownActions banned={record.mlg_users_deleted == 1 ? true : false} reload={reload} setReload={setReload} currentid={user.user.mlg_users_id} id={record.mlg_users_id} /> : <></>}
          </>
        )
      },
    },
  ];

  return (
    <>
    <Space.Compact size="large" style={{marginBottom: "20px", width:'100%', gap: "10px"}}>
      <Input onChange={debouncedOnChange} addonBefore={<SearchOutlined />} placeholder="Wyszukaj pracownika po #ID, imieniu i nazwisku, e-mailu, numerze telefonu..." />
      <Button onClick={() => navigate("/pracownicy/nowy-pracownik")}>Dodaj nowego pracownika</Button>
    </Space.Compact>
    <Spin spinning={loading}>
      <Table
      size="small"
      style={{overflowX: "auto"}}
        columns={columns}
        dataSource={data}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          pageSizeOptions: ['1', '20', '30', '50'],
          onShowSizeChange: handlePageSizeChange,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
          locale: {
            items_per_page: '/ stronę', // Customize the text in the select dropdown
          },
        }}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Brak danych' }}
      />
    </Spin>
    </>
  );
};

export default UserTable;