import React, { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Menu, theme, Image, Space } from 'antd';
import BreadcrumbItemLink from '../components/breadcrumbItemLink';
import RolesForm from './components/form';
import axios from 'axios';
const Permissions = () => {
  const [rolesId, setRolesId] = useState(null)
  const [blocked, setBlocked] = useState(false)
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [parent, setParent] = useState(0)
  const [reloadTree, setReloadTree] = useState(0)
  useEffect(() => {
      const fetchData = async () => {
      try {
          const response = await axios.post('/perms/get/roles/tree');
          setTreeData(response.data)
      } catch (error) {
          console.error('Errord fetchsing sdata:', error);
      }
      setLoading(false)
      };

      fetchData();
  }, [reloadTree])

  const removeNew = () => {
    const updatedTreeData = removeChildWithKeyNew(treeData);
    setTreeData(updatedTreeData);
  }

  const removeChildWithKeyNew = (nodes) => {
    return nodes.filter(node => node.key !== "new")
        .map(node => {
            if (node.children && node.children.length > 0) {
                return { ...node, children: removeChildWithKeyNew(node.children) };
            }
            return node;
        });
  }

  const updateTitleForKeyNew = (nodes, newTitle, nodeKey) => {
    return nodes.map(node => {
        // If this node is the one we're looking for, update its title
        if (node.key === nodeKey) {
            return { ...node, title: newTitle };
        }

        // If this node has children, recursively update them
        if (node.children) {
            return { ...node, children: updateTitleForKeyNew(node.children, newTitle) };
        }

        // If this node is not the one we're looking for and has no children, leave it as is
        return node;
    });
  }

  const changeTitleOfNew = (newTitle, node) => {
      setTreeData(currentTreeData => updateTitleForKeyNew(currentTreeData, newTitle, node));
  }
  return (
    <>
        <Breadcrumb
        style={{
            margin: '16px 0',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Role i uprawnienia</Breadcrumb.Item>
        </Breadcrumb>
        <RolesForm 
          setRolesId={setRolesId} 
          rolesId={rolesId} 
          setBlocked={setBlocked} 
          blocked={blocked} 
          treeData={treeData} 
          setTreeData={setTreeData} 
          removeNew={removeNew}
          changeTitleOfNew={changeTitleOfNew}
          loading={loading}
          setLoading={setLoading}
          parent={parent}
          setParent={setParent}
          reloadTree={reloadTree}
          setReloadTree={setReloadTree}
        ></RolesForm>
    </>
  );
};
export default Permissions;