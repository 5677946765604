import { useEffect, useState } from 'react';
import PrivateRoute from '../../../components/privateroute';
import {Router, Route, Routes, Navigate } from 'react-router';
import { Layout, Flex} from 'antd';
import React, { useMemo } from 'react';
import { useNavigate, redirect } from "react-router-dom";
import { ForwardOutlined, LoginOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import {
    Form,
    Header,
    Input,
    Image,
    Tooltip,
    notification,
    Space,
    Card
} from 'antd'
import Button from '../../../../../components/Button'
import { Typography } from 'antd';
import axios from 'axios';
import Logo from '../../../../../assets/images/MarshalMiniLogo.png'
import LogoutButton from '../logout';
import { Skeleton } from 'antd';
import getGetParameter from '../../../../../functions/getGetParameter';
const {Content} = Layout;
const { Title, Text } = Typography;
export default function Redirects(props) {

    const [data, setData] = useState(null)
    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const appParam = getGetParameter('app');
        // console.log(appParam)
        const dataFetch = async () => {
            try {
                if(appParam == null) {
                    const response = await axios.post('/auth/display/apps',{
                        key: appParam
                    });
                    setData(response.data.data)
                } else {
                    const response = await axios.post('/auth/get/app',{
                        key: appParam
                    });
                    window.location.href=response.data.url
                }
            } catch (error) {
              console.log(error)
            }
        };
        dataFetch()
    }, []);

    const refresh = (url) => {
        window.location.href = url 
    }

    function getFirstLetters(str) {
        return str.split(' ') // Split the string into an array of words
                  .map(word => word[0]) // Map through each word and get the first letter
                  .join(''); // Join the letters with a space
    }

    return (
        <Space direction="vertical">
            <Space direction="vertical" size={16} style={{
            maxWidth: 550,
            }}>
            <Title level='2'>Logowanie udane</Title>
            <Text style={{marginBottom: '20px', display: "block"}}>Aby przejść do danej aplikacji zaloguj się poprzez centralny system logowania.</Text>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px', rowGap: '10px'}}>
            {data ? data.map((component) => (
                <div 
                    className='blocklink'
                    onClick={() => refresh(component.mlg_apps_url)} 
                    style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', gap: '10px', width: "100%", border: '1px solid #E8E8E8', boxShadow: '2px 2px 4.6px 0px rgba(0, 0, 0, 0.07)', padding: '20px'}}
                >
                    {<Image preview={false} src={Logo}></Image>}
                    <div style={{fontSize: '20px', textAlign: 'center'}}>{component.mlg_apps_name}</div>
                </div>
            )) : <Skeleton active />}
            </div>
            <LogoutButton></LogoutButton>
            </Space>
        </Space>
    );
  };