import { useState } from 'react';
import PrivateRoute from '../../../components/privateroute';
import {Router, Route, Routes, Navigate } from 'react-router';
import { Layout, Flex} from 'antd';
import React, { useMemo } from 'react';
import { useNavigate, redirect } from "react-router-dom";
import { ForwardOutlined, LoginOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import {
    notification 
} from 'antd'
import axios from 'axios';
import Button from '../../../../../components/Button'
import handleLogout from '../logoutFunction';
import { Typography } from 'antd';
export default function LogoutButton(props) {
    return (
        <Button style={{width: '100%'}} onClick={handleLogout} icon={<LoginOutlined />}>Wyloguj się</Button>
    );
  };