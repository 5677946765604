import React from 'react';
import { Button } from 'antd';

const CustomButton = ({ customStyle, ...props }) => {
  // Default styles for the button
  const defaultStyle = {
    color: 'white',
    padding: '5px 30px',
    height: 'auto',
    borderRadius: '2px',
    background: '#B57531',
  };

  // Merge default styles with custom styles from props
  const mergedStyles = { ...defaultStyle, ...customStyle };

  return <Button style={mergedStyles} {...props} />;
};

export default CustomButton;
