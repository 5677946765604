export default function getGetParameter(parameterName) {
    // Access the URL hash and split it to separate the path and parameters
    const hashParts = window.location.hash.split('?');
    
    // Check if there are parameters in the hash
    if (hashParts.length < 2) {
        return null; // No parameters found
    }

    // Extract the parameters part
    const paramsString = hashParts[1];
    const params = paramsString.split('&'); // Split by `&`

    // Iterate over parameters to find the required one
    for (let i = 0; i < params.length; i++) {
        let pair = params[i].split('='); // Split key and value
        if (pair[0] === parameterName) {
            return pair[1];
        }
    }

    return null; // Parameter not found
}