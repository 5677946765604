import { useState, useEffect } from 'react';
import PrivateRoute from '../components/privateroute';
import {Router, Route, Routes, Navigate } from 'react-router';
import { Layout, Flex} from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined, CheckOutlined } from '@ant-design/icons';
import {
    Form,
    Header,
    Input,
    Image,
    Tooltip,
    notification 
} from 'antd'
import Button from '../../../components/Button'
import { Typography } from 'antd';
import Logo from '../../../assets/images/MarshalLogo.png'
import axios from 'axios';
import FormLogin from './components/form'
import Redirects from './components/redirects'
const {Content} = Layout;
export default function Login() {

    const [loggedin, setLoggedin] = useState(false);
    return (
        <Layout style={{minHeight:"100vh", background:'white'}}>
        <Content>
            <Flex style={{height: "100vh"}}>
                <Flex className='fullwidthmobile' style={{justifyContent: 'center', alignItems: 'center', width: '60%' }}>
                  {!loggedin ? <FormLogin setLoggedin={setLoggedin}></FormLogin> : <Redirects></Redirects>}
                </Flex>
                <Flex className='hideMobile' style={{justifyContent: 'center',width: '40%'}} column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}>
                    <Layout style={{ background: 'white', padding: "30px"}}>
                        <Content style={{ padding: '20px', background: "#E1C8AD", borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Image preview={false} src={Logo}></Image></Content>
                    </Layout>
                </Flex>
            
            </Flex>
        </Content>
      </Layout>
    );
  };