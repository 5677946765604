import { useState, useEffect } from 'react';
import { Table, Spin, Badge, Select, Space, Input, Typography } from 'antd';
import { useNavigate } from 'react-router';
import Button from '../../../../../components/Button';
import axios from 'axios';
import {
  SearchOutlined
} from '@ant-design/icons';
const {Text, Title} = Typography

const ActiveTable = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [user, setUser] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    search: ""
  });
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/auth/get/user');
        setUser(response.data)
      } catch (error) {
        console.error('Errord fetchsing sdata:', error);
      }
    };

    fetchData();
  }, [])

  const fetchData = async (page, pageSize) => {
    try {
      setLoading(true);

      // Replace 'yourApiEndpoint' with your actual server API endpoint
      const response = await axios.post('/users/list', {
        pagination: { ...pagination, current: page, pageSize: pageSize },
      });

      setData(response.data.rows);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, reload]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handlePageSizeChange = (value) => {
    setPagination({
      ...pagination,
      pageSize: value,
      current: 1, // Reset to the first page when changing page size
    });
  };

  const columns = [
    // Define your table columns here
    {
      title: 'ID',
      dataIndex: 'mlg_users_id',
      key: 'mlg_users_id',
    },
    {
      title: 'Imię i nazwisko',
      dataIndex: 'mlg_users_fullname',
      key: 'mlg_users_fullname',
    },
    {
      title: 'Status sesji',
      dataIndex: 'active_session',
      key: 'active_session',
      render: (item, record) => {
        return <Badge style={{minWidth: "100px"}} status={item ? 'success' : 'error'} text={item ? 'Aktywny' : 'Nieaktywny'} />
      },
    },
  ];

  return (
    <>
    <Space direction="vertical" style={{padding: "10px", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
      <Title level={4}>Aktywni użytkownicy</Title>
    <Spin spinning={loading}>
      <Table
      size="small"
      style={{overflowX: "auto"}}
        columns={columns}
        dataSource={data}
        pagination={{
          ...pagination,
          showSizeChanger: false,
          pageSizeOptions: ['1', '20', '30', '50'],
          onShowSizeChange: handlePageSizeChange,
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
          locale: {
            items_per_page: '/ stronę', // Customize the text in the select dropdown
          },
        }}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Brak danych' }}
      />
    </Spin>
    </Space>
    </>
  );
};

export default ActiveTable;