import axios from "axios";
import { useState, useEffect } from "react";
import { Tree, Button, Popconfirm, Spin, notification } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
export default function RolesTree(props) {
    const {rolesId, setRolesId, blocked, setBlocked, treeData, setTreeData, removeNew, changeTitleOfNew, loading, setLoading, setParent} = props    
      const onAddChild = (parentKey) => {
        const newNode = { title: "Nowa rola", key: 'new' };
    
        const updateTreeData = (data, key, child) => {
          return data.map(item => {
            if (item.key === key) {
              if (!item.children) {
                item.children = [];
              }
              item.children.push(child);
            } else if (item.children) {
              updateTreeData(item.children, key, child);
            }
            return item;
          });
        };
    
        const newTreeData = updateTreeData(treeData, parentKey, newNode);
        setTreeData([...newTreeData]);
        setRolesId('new')
        setBlocked(true)
        setParent(parentKey)
      };

      const onRemoveChild = async (childKey) => {
        setRolesId(1)
        const removeChild = (data, childKey) => {
          return data.filter(item => {
            if (item.key === childKey) {
              return false; // Exclude the node with the specified key
            } else if (item.children) {
              item.children = removeChild(item.children, childKey);
            }
            return true;
          });
        };
        const response = await axios.post('/perms/delete/role',{
          id: childKey
        });
        if(response.data.status == true){
          notification.success({
            message: response.data.message,
            duration: 3
          });
        } else if(response.data.status == false) {
          notification.error({
            message: response.data.message,
            duration: 3
          });
        }
        const newTreeData = removeChild(treeData, childKey);
        setTreeData([...newTreeData]);
      };
    
      const renderTreeNodes = (data,parent) =>
        data.map((item) => {
            return(
          <Tree.TreeNode
          active={true}
          className="nodeFullWidth"
          style={{width: "100%", margin: "5px 0"}}
            title={(
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span style={{fontSize: "18px"}}>{item.title}</span>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {item.key != 'new' ?<Button disabled={blocked} style={{marginLeft: "10px"}} size={"small"} shape="circle" icon={<PlusOutlined />} onClick={() => onAddChild(item.key)}></Button> : <></>}
                <Popconfirm
                    title="Jesteś pewny?"
                    onConfirm={() => onRemoveChild(item.key)}
                    okText="Tak"
                    cancelText="Nie"
                >
                {item.key != 'new' ?<Button type="danger" style={{marginLeft: "10px"}} size={"small"} shape="circle" icon={<DeleteOutlined />}></Button> : <></>}
                </Popconfirm>
                {item.key != 'new' ? <Button type="danger" onClick={() => {setBlocked(false);removeNew();setRolesId(item.key)}} style={{marginLeft: "10px"}} size={"small"} shape="circle" icon={<EditOutlined />}></Button> : <></>}
                </div>
              </div>
            )}
            key={item.key}
          >
            {item.children && renderTreeNodes(item.children, item.key)}
          </Tree.TreeNode>
        )});
    
      return (
        <Spin spinning={loading}>
        <Tree autoExpandParent={true} showLine defaultExpandAll={true}>
          {renderTreeNodes(treeData,0)}
        </Tree>
        </Spin>
      );
    };