import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Space, Flex, Drawer, Tooltip, Button } from 'antd';
import AvatarUser from '../avatar';
import { useNavigate } from 'react-router';
import LogoutButton from '../../login/components/logout';
import Logo from '../../../../assets/images/MarshalLogo.png';
import LogoMini from '../../../../assets/images/MarshalMiniLogo.png';
import Idle from '../idletimer';
import { useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import isCan from '../../../../components/isCan';
const { Header, Content, Footer, Sider } = Layout;

async function getItem(label, key, icon, children, url = null, perms) {
  return await isCan(perms) ? {
    key,
    icon,
    children,
    label,
    url
  } : null;
}

const items = [
  await getItem('Statystyki', '/statystyki', <PieChartOutlined />, null, '/statystyki', ['can_change_settings']),
  await getItem('Pracownicy', '/pracownicy', <DesktopOutlined />, null, '/pracownicy', ['can_change_settings']),
  await getItem('Role i uprawnienia', '/role', <LockOutlined />, null, '/role', ['can_change_settings']),
];

const AppLayout = (props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState(false);

  const handleMenuClick = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem && selectedItem.url) {
      setOpened(false)
      navigate(selectedItem.url)
    }
  };

  const onClose = () => {
    setOpened(false);
  };

  const { content } = props;

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Idle></Idle>
      <Drawer title="" placement="right" onClose={onClose} open={opened}>
        <Space style={{ padding: '20px' }}>
          <Image preview={false} src={!collapsed ? Logo : LogoMini}></Image>
        </Space>
        <Menu
          selectedKeys={[location.pathname]}
          defaultSelectedKeys={['']}
          mode="inline"
          items={items}
          onClick={handleMenuClick}
        />
      </Drawer>
      <Sider
      className='sider'
        style={{ background: 'white' , width: "400px"}}
        collapsible
        width="250px"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Space style={{ padding: '20px' }}>
          <Image preview={false} src={!collapsed ? Logo : LogoMini}></Image>
        </Space>

        <Menu
          selectedKeys={[location.pathname]}
          mode="inline"
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: "0 20px",
            background: 'white',
            display: "flex",
            gap: "15px",
            justifyContent: "flex-end",
            alignItems: 'center'
          }}
        >
          <Button onClick={() => {setOpened(true)}} className='mobilemenu' icon={<MenuOutlined />} />
          <AvatarUser></AvatarUser>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          {content}
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Weblance Copyright
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
