import React, { useState, useEffect } from 'react';
import { Button, Drawer, Table, Spin, Badge } from 'antd';
import axios from 'axios';
const UserHistory = (props) => {
    const [historyOpen, setHistoryOpen] = useState(false)
    const [history, setHistory] = useState(null)
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleTableChange = (pagination) => {
      setPagination(pagination);
    };
  
    const handlePageSizeChange = (value) => {
      setPagination({
        ...pagination,
        pageSize: value,
        current: 1, // Reset to the first page when changing page size
      });
    };

    const formatUnixTime = (unixTime) => {
      const date = new Date(unixTime * 1000); // Convert seconds to milliseconds
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      const formattedTime = `${hours}:${minutes} ${year}-${month}-${day}`;
      return formattedTime;
    }

    const columns = [
      // Define your table columns here
      {
        title: 'Czas zdarzenia',
        dataIndex: 'mlg_login_logs_datetime',
        key: 'mlg_login_logs_datetime',
        render: (item, record) => {
          return formatUnixTime(item)
        },
      },
      {
        title: 'Użyty login',
        dataIndex: 'mlg_login_logs_used_username',
        key: 'mlg_login_logs_used_username',
      },
      {
        title: 'IP',
        dataIndex: 'mlg_login_logs_ip',
        key: 'mlg_login_logs_ip',
      },
      {
        title: 'Kraj',
        dataIndex: 'mlg_login_logs_country',
        key: 'mlg_login_logs_country',
      },
      {
        title: 'Status zdarzenia',
        dataIndex: 'mlg_login_logs_login_failed',
        key: 'mlg_login_logs_login_failed',
        render: (item, record) => {
          return <Badge style={{minWidth: "100px"}} status={record.mlg_login_logs_login_failed == 0 ? 'success' : 'error'} text={record.mlg_logs_statuses_name} />
        },
      },
    ];

    const fetchData = async (page, pageSize) => {
      try {
        setLoading(true);
  
        // Replace 'yourApiEndpoint' with your actual server API endpoint
        const response = await axios.post('/user/history', {
          pagination: { ...pagination, current: page, pageSize: pageSize },
          id: props.id
        });
  
        setData(response.data.rows);
        setPagination({
          ...pagination,
          total: response.data.total,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchData(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize, reload]);


  return (
    <>
      <Button type="primary" onClick={() => {setHistoryOpen(!historyOpen)}}>
        Historia użytkownika
      </Button>
      <Drawer size='large' title="Historia użytkownika" placement="right" onClose={() => setHistoryOpen(false)} open={historyOpen}>
          <Spin spinning={loading}>
          <Table
          size="small"
          style={{overflowX: "auto"}}
            columns={columns}
            dataSource={data}
            pagination={{
              ...pagination,
              showSizeChanger: true,
              pageSizeOptions: ['1', '20', '30', '50'],
              onShowSizeChange: handlePageSizeChange,
              showQuickJumper: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} elementów`, // Customize total display
              locale: {
                items_per_page: '/ stronę', // Customize the text in the select dropdown
              },
            }}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Brak danych' }}
          />
        </Spin>
      </Drawer>
    </>
  );
};
export default UserHistory;