import React, { useState, useEffect } from 'react';
import {CloseOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {Space, Flex, Divider, Form, Label,Typography, Input, Select, notification, Switch, Tooltip, Skeleton, Tabs } from 'antd';
import {Button} from 'antd';
import { useNavigate } from 'react-router';
import ButtonCustom from '../../../../../components/Button'
import { useParams } from 'react-router';
import axios from 'axios'
import UserHistory from '../history';
const {Title} = Typography
const { TabPane } = Tabs;
const EditingForm = () => {
    const { userid } = useParams();
    const navigate = useNavigate()
    const [credentials, setCredentials] = useState(null);
    const [roles, setRoles] = useState(null)
    const [users, setUsers] = useState(null)
    const [apps,setApps] = useState(null);
    const [perms, setPerms] = useState([])

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.post('/perms/get/apps');
            setApps(response.data)
        } catch (error) {
            console.error('Errord fetchsing sdata:', error);
        }
        try {
            const response = await axios.post('/perms/get/roles');
            setRoles(response.data)
        } catch (error) {
            console.error('Errord fetchsing sdata:', error);
        }
        try {
            const response = await axios.post('/perms/get/users', {
                userid: userid
            });
            setUsers(response.data)
            // console.log(response.data)
        } catch (error) {
            console.error('Errord fetchsing sdata:', error);
        }
        try {
            const response = await axios.post('/user/get',{
                id: userid
            });
            console.log(response.data)
            setCredentials(response.data)
        } catch (error) {
            console.error('Errord fetchsing sdata:', error);
        }
        };
    
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.post('/perms/get/perms');
            setPerms(response.data)
            console.log(response.data)
        } catch (error) {
            console.error('Errord fetchsing sdata:', error);
        }
        };
  
        fetchData();
    }, [userid])
    


    useEffect(() => {
        if(isValidPass()){
            setNotPassable(false)
        } else {
            setNotPassable(true)
        }
    }, [credentials])

    const [notPassable, setNotPassable] = useState(false)

    const [loading, setLoading] = useState(false)


    const openErrorNotification = (data) => {
        notification.error({
          message: 'Nie można usunąć danego elementu',
          description: "Dany pracownik musi mieć przynajmniej po jednym typie danych kontaktowych.",
          duration: 3
        });
    };
    const openSuccessNotification = (data) => {
        notification.success({
          message: data.message,
          description: data.desc,
          duration: 3
        });
    };


    const switchChange = (perm) => {
        // Create a new copy of credentials
        var atomperms = credentials.atomperms.slice(); // Copy array to avoid direct mutation
        const exists = atomperms.some(obj => obj.perm === perm.key && obj.app === perm.app);
    
        if (exists) {
            // Filter out the matching object
            atomperms = atomperms.filter(obj => !(obj.perm === perm.key && obj.app === perm.app));
        } else {
            // Add the new object if it doesn't exist
            atomperms.push({ perm: perm.key, app: perm.app });
        }
    
        // Update the state
        setCredentials({ ...credentials, atomperms });
    };

    
    const onFinish = async () => {
        setLoading(true)
        try {
            const response = await axios.post('/user/edit',{
                credentials: credentials,
                id: userid
            });
            setLoading(false)
            if(response.data.status){
                openSuccessNotification({
                    message: "Dane pracownika zaktualizowane"
                })
            }
        } catch (error) {
        console.error('Errord fetchsing sdata:', error);
        }
    }
    
    const onFinishFailed = () => {

    }

    const handleSwitchChange = (checked) => {
        const newActiveValue = checked ? 1 : 0;
        setCredentials({
          ...credentials,
          active: newActiveValue
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    const addNewElement = () => {
        const updatedContacts = [...credentials.contacts];
        updatedContacts.push([
            !hasGivenSameType(1,3) ? 1 : 2,
            1,
            '']
            )
    
        setCredentials({
          ...credentials,
          contacts: updatedContacts
        });
    }

    const handleInputChangeContacts = (e, index, pos) => {
        const updatedContacts = [...credentials.contacts];
        updatedContacts[index][pos] = e;
        // console.log(pos,credentials.contacts[index][2])
        if(pos == 2 && credentials.contacts[index][0] == 1){
            setNotPassable(!isValidEmail(e))
        }
        if(pos == 2 && credentials.contacts[index][0] == 2){
            setNotPassable(!isValidPhone(e))
        }
        setCredentials({
          ...credentials,
          contacts: updatedContacts
        });
    };
    const handleInputChangeRoles = (e) => {  
        setCredentials({
          ...credentials,
          roles: e
        });
    };
    const handleInputChangeUsers = (e, app) => {  
        var updatedUsers = credentials.users
        updatedUsers[app] = e
        // console.log(updatedUsers)
        // console.log(app, e)
        setCredentials({
          ...credentials,
          users: updatedUsers
        });
    };
    const handleInputChangeApps = (e) => {  
        setCredentials({
          ...credentials,
          apps: e
        });
    };

    const hasDuplicateType = (typeToCheck) => {
        const types = credentials.contacts.map((contact) => contact[0]);
        const occurrences = types.filter((type) => type === typeToCheck).length;
        return occurrences > 1;
    };
    
    const hasGivenSameType = (typeToCheck, count) => {
        const types = credentials.contacts.map((contact) => contact[0]);
        const occurrences = types.filter((type) => type === typeToCheck).length;
        return occurrences >= count
    };
    const isValidPass = () => {
        if(credentials != null){
            if(credentials.password == ""  && credentials.passwordrepeat == ""){
                return true
            }
            if(credentials.password == credentials.passwordrepeat && credentials.passwordrepeat.length > 8 && credentials.password.length > 8){
                return true
        }
        }
        return false
    }
    const isValidLength = (x) => {
        if(x.length == 0){
            return true
        }
        if(x.length >= 8){
            return true
        }
        return false
    }
    const isValidEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!emailRegex.test(email)) {
            return false; // Invalid email format
        }
        const domain = email.split('@')[1];
        if(domain !== 'marshal.pl'){
            return false;
        }
        return domain === 'marshal.pl';
    };
    const isValidPhone = (phone) => {
        // Regular expression for basic email validation
        const phoneRegex = /^\d{9}$/;
        
        if (!phoneRegex.test(phone)) {
          return false; // Invalid email format
        }
        return true
    };

    const removeElement = (index) => {
        // Create a new array without the element at the specified index
        if(hasDuplicateType(credentials.contacts[index][0])){
            const updatedContacts = [...credentials.contacts.slice(0, index), ...credentials.contacts.slice(index + 1)];
            
            // Update the state with the new contacts array
            setCredentials({
              ...credentials,
              contacts: updatedContacts
            });
            return true;
        }
        openErrorNotification()
        return false;
    };

    return (
        <><Form
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <Flex gap={10} direction="vertical" justify={'space-between'} style={{padding: "20px 10px", width: "100%", marginBottom: "20px", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
            {credentials != null ? <Title style={{margin: 0}} level={4}>{credentials.names}</Title> : <Skeleton paragraph={{ rows: 0 }} active/> }
            <Space>
                <UserHistory id={userid}></UserHistory>
                <Button onClick={()=> navigate("/pracownicy")}>Anuluj</Button>
                <Button disabled={notPassable} htmlType="submit">Zapisz</Button>
            </Space>
        </Flex>
        <Space className={['fullwidth', "mobileColLG"]} align="start" style={{gap: "20px", width: '100%'}}>
            <Space direction="vertical" style={{padding: "10px", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                <Divider>Dane podstawowe</Divider>
                {credentials != null ? <>
                    <Form.Item label='Imię'>
                    <Input 
                        name='names' 
                        value={credentials.names}
                        onChange={handleInputChange}
                        placeholder='Imię i nazwisko' 
                        size='large' 
                        style={{width: "100%"}} 
                        required/>
                    </Form.Item>
                    <Form.Item label='Nazwa użytkownika'>
                    <Input 
                        name='nick' 
                        value={credentials.nick}
                        onChange={handleInputChange}
                        placeholder='Nazwa użytkownika' 
                        size='large' 
                        style={{width: "100%"}} 
                        required/>
                    </Form.Item>
                    <Form.Item label='Hasło' extra="Hasło musi składać się przynajmniej z 8 znaków.">
                    <Input.Password
                        name='password' 
                        type="password"
                        status={isValidLength(credentials.passwordrepeat) ? "" : "error"}
                        value={credentials.password}
                        onChange={handleInputChange}
                        placeholder='Hasło' 
                        size='large' 
                        style={{width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item label='Powtórz hasło'>
                    <Input.Password
                        name='passwordrepeat' 
                        type="password"
                        status={isValidPass() && isValidLength(credentials.passwordrepeat) ? "" : "error"}
                        value={credentials.passwordrepeat}
                        onChange={handleInputChange}
                        placeholder='Powtórz hasło' 
                        size='large' 
                        style={{width: "100%"}}
                        />
                    </Form.Item>
                    
                    {credentials.contacts.map((input, index) => (
                        <Space align='end' className={["contact"+index,"mobileColSM"]} style={{padding: "10px", background:"red", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                            <Form.Item label='Typ kontaktu' style={{margin: 0}}>
                                <Select
                                    className={"selectResponsive"}
                                    name={"type__"+index}
                                    defaultValue={input[0]}
                                    onChange={(e) => handleInputChangeContacts(e, index, 0)} 
                                    options={[{ value: 1, label: 'E-mail', disabled: hasGivenSameType(1,3) }, { value: 2, label: 'Numer telefonu', disabled: hasGivenSameType(2,3) }]}
                                    size="large" 
                                    style={{ width: 150, margin: '0' }}
                                    required
                                />
                            </Form.Item>
                            <Form.Item label='Dostęp do logowania' style={{margin: 0}}>
                                <Select
                                    className="selectResponsive"
                                    name={"access__"+index}
                                    defaultValue={input[1]}
                                    onChange={(e) => handleInputChangeContacts(e, index, 1)}
                                    options={[{ value: 0, label: 'Nie' }, { value: 1, label: 'Tak' }]}
                                    size="large"
                                    style={{ width: 150, margin: '0' }}
                                    required
                                />
                            </Form.Item>

                            <Form.Item
                                label={input[0] == 1 ? 'E-mail' : "Numer telefonu"} 
                                style={{margin: 0, width: '100%'}}>
                                <Input   
                                    suffix={
                                        input[0] == 1 ? (isValidEmail(input[2]) ? "" : 
                                        <Tooltip title="Wpisz adres e-mail zgodny z domeną aplikacji">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                        ) : (isValidPhone(input[2]) ? "" : 
                                        <Tooltip title="Wpisz numer telefonu zgodny z formatem XXXXXXXXX (9 cyfr, bez dodatkowych znaków)">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                        )

                                    }                  
                                    status={ input[0] == 1 ? (isValidEmail(input[2]) ? "" : "error") : (isValidPhone(input[2]) ? "" : "error")}               
                                    name={"value__"+index} 
                                    onChange={(e) => handleInputChangeContacts(e.target.value, index, 2)}
                                    value={input[2]}
                                    placeholder='Wartość' 
                                    size='large' 
                                    style={{width: "100%", margin: '0'}} 
                                    required/>
                            </Form.Item>
                            <Button disabled={!hasDuplicateType(credentials.contacts[index][0])} size="large" onClick={() => removeElement(index)} icon={<CloseOutlined/>}></Button>
                        </Space>
                    ))}

            <Button disabled={(hasGivenSameType(1,3) && hasGivenSameType(2,3))} size="large" onClick={() => addNewElement()} icon={<PlusOutlined/>}></Button>
            </> : <Skeleton paragraph={{ rows: 10 }} active/> }
            </Space >
            <Space className='fullwidth' direction="vertical" style={{padding: "10px", background:"red", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                <Divider>Uprawnienia i bezpieczeństwo</Divider>
                {
                    credentials != null ? <>
                <Form.Item label='Role użytkownika'>
                    <Select
                        mode="multiple"
                        name={"roles"}
                        defaultValue={credentials.roles} 
                        options={roles}
                        size="large" 
                        onChange={(e) => handleInputChangeRoles(e)}
                        style={{ width: '100%', margin: '0' }}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                </Form.Item>
                {/* <Form.Item label='Przypisani użytkownicy'>
                    <Select
                        mode="multiple"
                        name={"users"}
                        defaultValue={credentials.users} 
                        options={users}
                        size="large" 
                        onChange={(e) => handleInputChangeUsers(e)}
                        style={{ width: '100%', margin: '0' }}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                </Form.Item> */}
                <Space className='fullwidth' direction="vertical" style={{padding: "0 10px", width: "100%"}}>
                    <Divider>Subużytkownicy</Divider>
                    <Form layout='vertical'>
                        <Tabs defaultActiveKey="1">
                                {apps.map((tab) => 
                                    <TabPane tab={tab.label} key={tab.value}>
                                            <>
                                            <Form.Item label='Wybierz subużytkowników'>
                                                <Select
                                                    mode="multiple"
                                                    name={"users"}
                                                    defaultValue={credentials.users[tab.value]} 
                                                    value={credentials.users[tab.value]}
                                                    options={users}
                                                    size="large" 
                                                    onChange={(e) => handleInputChangeUsers(e, tab.value)}
                                                    style={{ width: '100%', margin: '0' }}
                                                    filterOption={(input, option) =>
                                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                />
                                            </Form.Item>
                                            </>
                                    </TabPane>
                                )}
                        </Tabs>
                    </Form>
                </Space >
                <Space className='fullwidth' direction="vertical" style={{padding: "0 10px", width: "100%"}}>
                    <Divider>Uprawnienia atomowe (nadrzędne w stosunku do ról)</Divider>
                    <Form layout='vertical'>
                        <Tabs defaultActiveKey="1">
                                {apps.map((tab) => 
                                    <TabPane tab={tab.label} key={tab.value}>
                                            {perms.map(perm => (
                                                <>
                                                {perm.app == tab.value || perm.app == 0 ?  
                                                <div style={{marginBottom:"10px"}}>
                                                    <label>
                                                        <Switch style={{marginRight: "10px"}} onChange={() => switchChange({key: perm.key, app: tab.value})} checked={credentials.atomperms.some(obj => obj.perm === perm.key && obj.app === tab.value) ? true : false}/>
                                                        {perm.name}
                                                    </label>
                                                </div>
                                                : 
                                                <></>
                                                }
                                                </>
                                            ))}    
                                    </TabPane>
                                )}
                        </Tabs>
                    </Form>
                </Space >
                <Form.Item label='Dostęp do aplikacji'>
                    <Select
                        mode="multiple"
                        name={"apps"}
                        defaultValue={credentials.apps} 
                        onChange={(e) => handleInputChangeApps(e)}
                        options={apps}
                        size="large" 
                        style={{ width: '100%', margin: '0' }}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                </Form.Item>
                <Form.Item label='Aktywacja użytkownika'>
                    <Switch name="active" onChange={handleSwitchChange} checked={credentials.active} />
                </Form.Item> </>
                    : <Skeleton paragraph={{ rows: 10 }} active/>
                }
            </Space >
        </Space>
        </Form></>
    );
};
export default EditingForm;