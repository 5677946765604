import React, {useState} from 'react';
import { Menu, Dropdown, Button, message, Modal, notification } from 'antd';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { EditOutlined,CaretDownOutlined, LogoutOutlined, LockOutlined, DeleteOutlined, RollbackOutlined } from '@ant-design/icons';

const DropdownActions = (props) => {
  const navigate = useNavigate()
  const {reload, setReload, banned} = props
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleEdit = (id) => {
    navigate("/pracownicy/"+id)
  };

  const handleLogout = async (id) => {
    const response = await axios.post('/user/logout', {
      id: id,
    });
    notification.success({
      message: response.data.message,
      duration: 3
    });
  };


  const handleDelete = () => {
    if(!banned){
      Modal.confirm({
        title: 'Usuwanie pracownika',
        okText: "Potwierdź",
        cancelText: "Anuluj",
        onOk: () => handleConfirmDelete(props.id),
        content: 'Tej operacji nie da się cofnąć!',
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn/>
            <OkBtn style={{ backgroundColor: 'green', color: 'white' }} />
          </>
        ),
      });
    } else {
      Modal.confirm({
        title: 'Przywracanie pracownika',
        okText: "Potwierdź",
        cancelText: "Anuluj",
        onOk: () => handleConfirmDelete(props.id),
        content: 'Przywrócony pracownik będzie miał takie same prawa jak przed usunięciem!',
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn/>
            <OkBtn style={{ backgroundColor: 'green', color: 'white' }} />
          </>
        ),
      });
    }
  };

  const handleConfirmDelete = async (id) => {
    try {
      // Send a POST request to the backend
      if(!banned){
        const response = await axios.post('/user/delete', {
          id: id,
        });
        notification.success({
          message: response.data.message,
          duration: 3
        });
      } else {
        const response = await axios.post('/user/restore', {
          id: id,
        });
        notification.success({
          message: response.data.message,
          duration: 3
        });
      }
      setReload(!reload)
    } catch (error) {
      notification.error({
        message: "Coś poszło nie tak, spróbuj ponownie.",
        duration: 3
      });
      console.error('Error sending POST request:', error);
    }
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false)
  };
  
  const menu = (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEdit(props.id)} icon={<EditOutlined />}>
        Edytuj
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => handleLogout(props.id)} icon={<LogoutOutlined />}>
        Wyloguj
      </Menu.Item>
      {props.currentid == props.id ? <></> :
      <Menu.Item key="delete" onClick={() => handleDelete(props.id)} icon={!banned ? <DeleteOutlined /> : <RollbackOutlined />} danger>
        {banned ? "Przywróć" : "Usuń"}
      </Menu.Item>}
    </Menu>
  );

  return (
    <Dropdown trigger={'click'} overlay={menu} placement="bottomRight">
      <Button style={{width: "100%"}} icon={<CaretDownOutlined />}>Akcje</Button>
    </Dropdown>
  );
};

export default DropdownActions;
