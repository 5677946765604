import { Route, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, Flex } from 'antd';
import Cookies from 'js-cookie';


export const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();
  const isLoginPage = location.pathname.includes('/login');


  useEffect(() => {
    // Add a request interceptor
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        // Modify config or add headers before the request is sent
        config.headers['Authorization'] = Cookies.get('mgltoken');
        config.headers['App'] = process.env.REACT_APP_APP_KEY;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // If the response is successful, just return it
        return response;
      },
      (error) => {
        // If the response status is 401, handle it here
        if (error.response && error.response.status === 408) {
          if (!window.location.href.includes('/login')) {
            // Trigger your action here, for example, redirect to login page
            console.log('Unauthorized request. Redirecting to login page.');
            Cookies.remove('mgltoken')
            // Replace the following line with your action, e.g., redirect to login page
            window.location.href = '#/login';
          }
        }

        // If it's not a 401 response, just throw the error again
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/auth/session/check');

        if (response.data.authenticated === true) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsAuthenticated(false);
      }
    };

    fetchData();
  }, [isAuthenticated]);

  if (isAuthenticated === null && !isLoginPage) {
    return <></>;
  }

  if (isAuthenticated === false && !isLoginPage) {
    return <Navigate to="/login" />;
  }

  if(isAuthenticated === true && isLoginPage){
    return <Navigate to="/" />;
  }
  if(isAuthenticated === false && isLoginPage){
    return children;
  }
  if(isAuthenticated === true && !isLoginPage){
    return children;
  }
};

export default PrivateRoute;
