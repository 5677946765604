export default function Theme(theme) {
    return {
        token: {
            colorPrimary: "#BD8136",
            borderRadius: "2px"
        },
        components: {
            Select: {
                multipleItemBg: "#F7F2E4"
            },
            Menu: {
                itemBg: 'white',
                itemSelectedBg: "#F7F2E4",
                itemSelectedColor: "#BD8136",
                itemBorderRadius: "0px",
                groupTitleFontSize: "25px",
                activeBarBorderWidth: "20px",
            },
            Layout: {
                triggerBg: '#BD8136',
            },
            Table: {
                headerBg: "#FBF8F1",
                headerBorderRadius: "0px"
            },
            Switch: {
                handleBg: "white"
            }

        },
      }
}