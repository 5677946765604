import React, { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

// This is the modified Higher-Order Component
const Can = ({ children, perms }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        const fetchPerms = async() => {
            try {
                const res = await axios.post('/perms/get/by/user');
                const userPerms = res.data;
                const allIncluded = perms.every(perm => userPerms.includes(perm));
                setHasPermission(allIncluded);
            } catch (error) {
                console.error('Error fetching permissions:', error);
                setHasPermission(false);
            }
            setIsLoading(false);
        }
        fetchPerms();
    }, [perms]);

    if (isLoading) {
        return null; // Or any other loading component
    }

    // Render the children only if the permission is granted
    return hasPermission ? children : null;
};

export default Can;
