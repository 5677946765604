import React from 'react';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
const BreadcrumbItemLink = (props) => {
  const navigate = useNavigate();
  const handleItemClick = (link) => {
    navigate(link)
  };

  return (
    <Breadcrumb.Item className='navigate' onClick={() => handleItemClick(props.url)}>
      {props.text}
    </Breadcrumb.Item>
  );
};

export default BreadcrumbItemLink;