import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme, Image, Space } from 'antd';
import BreadcrumbItemLink from '../components/breadcrumbItemLink';
import AddingForm from './components/form';
const UsersAdd = () => {
  return (
    <>
        <Breadcrumb
        style={{
            margin: '16px 0',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <BreadcrumbItemLink text="Pracownicy" url="/pracownicy"/>
        <Breadcrumb.Item>Nowy Pracownik</Breadcrumb.Item>
        </Breadcrumb>
        <AddingForm></AddingForm>
    </>
  );
};
export default UsersAdd;