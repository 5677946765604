import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import axios from 'axios';

const ActiveSwitchBlock = (props) => {
  const [switchValue, setSwitchValue] = useState(props.value);

  // Effect to synchronize internal state with incoming props
  useEffect(() => {
    setSwitchValue(props.value);
  }, [props.value]); // Dependency array includes props.value

  const handleSwitchChange = async (checked) => {
    setSwitchValue(checked);
    const response = await axios.post('/users/change/blocked', {
      switchValue: checked,
      id: props.id,
    });
  };

  return (
      <Switch onChange={handleSwitchChange} checked={switchValue == 1 ? true : false} />
  );
};

export default ActiveSwitchBlock;
