import React, { useState } from 'react';
import UserTable from './components/userTable';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Space } from 'antd';
import Logo from '../../../assets/images/MarshalLogo.png'
import Sider from '../../../components/Sider';
const { Header, Content, Footer } = Layout;


const Users = () => {
  return (
    <>
        <Breadcrumb
        style={{
            margin: '16px 0',
        }}
        >
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Pracownicy</Breadcrumb.Item>
        </Breadcrumb>
        <UserTable></UserTable>
    </>
  );
};
export default Users;