import React, { useState } from 'react';
import ActiveTable from './components/activeTable';
import ActivityTable from './components/activityTable';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Image, Space, Typography } from 'antd';
import Logo from '../../../assets/images/MarshalLogo.png'
import Sider from '../../../components/Sider';
import Can from '../../../components/Can';
const {Text} = Typography
const { Header, Content, Footer } = Layout;


const Stats = () => {
  return (
    <>
        <Breadcrumb
        style={{
            margin: '16px 0',
        }}
        >
        <Can perms={['can_base_edit', 'can_base_add']}>
          <Text>Dostęp przyznany</Text>
        </Can>
        <Breadcrumb.Item>Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item>Statystyki</Breadcrumb.Item>
        </Breadcrumb>
        <Space className={['fullwidth', "mobileColLG"]} align="start" style={{gap: "20px", width: '100%'}}>
        <ActiveTable></ActiveTable>
        <ActivityTable></ActivityTable>
        </Space>
    </>
  );
};
export default Stats;