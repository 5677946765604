import React, { useState, useEffect } from 'react';
import {CloseOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {Space, Flex, Divider, Form, Label,Typography, Input, Select, notification, Switch, Tooltip, Alert } from 'antd';
import {Button} from 'antd';
import { useNavigate } from 'react-router';
import ButtonCustom from '../../../../../components/Button'
import axios from 'axios'
import TreeWithButtons from '../tree';
import { Tabs } from 'antd';
const { TextArea } = Input;
const {Title} = Typography
const { TabPane } = Tabs;
const EditRole = (props) => {
    const {rolesId, setRolesId, blocked, setBlocked, treeData, setTreedata, removeNew, changeTitleOfNew, credentials, setCredentials,parent, setReloadTree, reloadTree} = props
    const navigate = useNavigate()



    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.post('/perms/get/perms');
            setPerms(response.data)
        } catch (error) {
            console.error('Errord fetchsing sdata:', error);
        }
        };
  
        fetchData();
    }, [rolesId])

    const [perms, setPerms] = useState([])
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        changeTitleOfNew(value, rolesId)
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    const switchChange = (key, index) => {
        // Create a new copy of credentials
        const updatedCredentials = { ...credentials };
    
        // Check if the perm key exists in the current tab's perms
        if (updatedCredentials.perms[index].perms.includes(key)) {
            // If it exists, remove it (switch turned off)
            updatedCredentials.perms[index].perms = updatedCredentials.perms[index].perms.filter(permKey => permKey !== key);
        } else {
            // If it doesn't exist, add it (switch turned on)
            updatedCredentials.perms[index].perms.push(key);
        }
    
        // Update the state
        setCredentials(updatedCredentials);
    }

    const onFinish = async () => {
        if(rolesId != null && rolesId != "new"){
            const response = await axios.post('/perms/update/role',{
                id: rolesId,
                perms: credentials
            });
            if(response.data.status){
                notification.success({
                    message: response.data.message,
                    duration: 3
                  });
            }
        }
        if(rolesId != null && rolesId == "new"){
            // console.log(parent)
            const response = await axios.post('/perms/add/role',{
                id: rolesId,
                perms: credentials,
                parent: parent
            });
            if(response.data.status){
                notification.success({
                    message: response.data.message,
                    duration: 3
                });
                setRolesId(response.data.id)
                setReloadTree(!reloadTree)
                setBlocked(false)
            }
        }

    }

    return (
        <>
        {rolesId == null && credentials == null ? <Alert message='Wybierz rolę lub dodaj nową'/> :
        <Form layout='vertical' onFinish={() => onFinish()}>
        <Space className={['fullwidth', "mobileColLG","permissions"]} align="start" style={{gap: "20px", width: '100%'}}>
            <Space direction="vertical" style={{maxWidth: "500px",padding: "0 10px", width: "100%"}}>
                <Divider>Dane roli</Divider>
                <Form.Item label="Nazwa roli">
                    <Input 
                        name='name' 
                        value={credentials != null ? credentials.name : null}
                        onChange={handleInputChange}
                        placeholder='Nazwa roli' 
                        size='large' 
                        style={{width: "100%"}} 
                        required
                    />
                </Form.Item>
                <Form.Item label="Opis roli">
                    <TextArea
                        name='desc' 
                        value={credentials != null ? credentials.desc : null}
                        onChange={handleInputChange}
                        placeholder='Opis roli' 
                        size='large' 
                        style={{width: "100%"}} 
                    />
                </Form.Item>

            </Space >
            <Space className='fullwidth' direction="vertical" style={{padding: "0 10px", width: "100%"}}>
                <Divider>Uprawnienia</Divider>
                <Form layout='vertical'>
                <Tabs defaultActiveKey="1">
                    {credentials != null ? credentials.perms.map((tab, index) => (
                        <TabPane tab={tab.title} key={tab.app}>
                            {perms.map(perm => (
                                <>
                                {perm.app == tab.app || perm.app == 0 ?  
                                <div style={{marginBottom:"10px"}}>
                                <label>
                                    <Switch style={{marginRight: "10px"}} onChange={() => switchChange(perm.key,index)} checked={tab.perms.includes(perm.key) ? true : false}/>
                                    {perm.name}
                                </label>
                                </div>
                                : 
                                <></>
                                }
                                </>
                            ))}     
                        </TabPane>
                    )) : []}
                </Tabs>
                </Form>
            </Space >
        </Space>
        <Space align='end'>
            <Button onClick={()=> {setRolesId(null); setBlocked(false); removeNew()}}>Anuluj</Button>
            <Button htmlType="submit">Zapisz</Button>
        </Space>
        </Form>}
        </>
    );
};
export default EditRole;