import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import './index.css'
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {ConfigProvider, theme} from 'antd';
import axios from "axios";
import PrivateRoute from "./routes/views/components/privateroute";
import Login from './routes/views/login'
import Stats from './routes/views/stats'
import Theme from './theme'
import AppLayout from "./routes/views/components/layout";
import Users from "./routes/views/users";
import UsersAdd from "./routes/views/useradd";
import UsersEdit from "./routes/views/useredit";
import Permissions from "./routes/views/permissions";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
  theme={Theme(theme)}
  >
    <Router>
      <Routes>
          <Route
            path="/login"
            element={
              <PrivateRoute>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/statystyki"
            element={
              <AppLayout content={
                <PrivateRoute>
                  <Stats />
                </PrivateRoute>
              }>
              </AppLayout>
            }
          />
          <Route
            path="/pracownicy"
            element={
              <AppLayout content={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }>
              </AppLayout>
            }
          />
          <Route
            path="/pracownicy/nowy-pracownik"
            element={
              <AppLayout content={
                <PrivateRoute>
                  <UsersAdd />
                </PrivateRoute>
              }>
              </AppLayout>
            }
          />
          <Route
            path="/role"
            element={
              <AppLayout content={
                <PrivateRoute>
                  <Permissions />
                </PrivateRoute>
              }>
              </AppLayout>
            }
          />
          <Route
            path="/pracownicy/:userid"
            element={
              <AppLayout content={
                <PrivateRoute>
                  <UsersEdit />
                </PrivateRoute>
              }>
              </AppLayout>
            }
          />
          <Route path="*" element={<Navigate to="/statystyki" />} />
      </Routes>
    </Router>
    </ConfigProvider>
);
