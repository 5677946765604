import React, { useState, useEffect } from 'react';
import {CloseOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons'
import {Space, Flex, Divider, Form, Label,Typography, Input, Select, notification, Switch, Tooltip, Spin } from 'antd';
import {Button} from 'antd';
import { useNavigate } from 'react-router';
import ButtonCustom from '../../../../../components/Button'
import axios from 'axios'
import TreeWithButtons from '../tree';
import EditRole from '../edit';
const {Title} = Typography
const RolesForm = (props) => {
    const {rolesId, setRolesId, blocked, setBlocked, treeData, setTreeData, removeNew, changeTitleOfNew, loading, setLoading, parent, setParent, reloadTree, setReloadTree} = props
    const navigate = useNavigate()
    const [credentials, setCredentials] = useState(null)
    const [roleLoading, setRoleLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
        setRoleLoading(true)
        try {
            const response = await axios.post('/perms/get/role',{
                id: rolesId
            });
            setCredentials(response.data)
            // console.log(credentials)
        } catch (error) {
            console.log(error)
        }
        setRoleLoading(false)
        };
        if(rolesId != null){
            fetchData();
        }
    }, [rolesId])

    return (
        <>
        <Flex gap={10} direction="vertical" justify={'space-between'} style={{padding: "20px 10px", width: "100%", marginBottom: "20px", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
            <Title style={{margin: 0}} level={4}>Role i uprawnienia</Title>
        </Flex>
        <Space className={['fullwidth', "mobileColLG","permissions"]} align="start" style={{gap: "20px", width: '100%'}}>
        <Space direction="vertical" style={{maxWidth: "500px",padding: "10px", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                <Divider>Role</Divider>
                <TreeWithButtons 
                    loading={loading} 
                    setLoading={setLoading} 
                    setRolesId={setRolesId} 
                    rolesId={rolesId} 
                    setBlocked={setBlocked} 
                    blocked={blocked} 
                    treeData={treeData} 
                    setTreeData={setTreeData} 
                    removeNew={removeNew}
                    setParent={setParent}
                    reloadTree={reloadTree}
                    setReloadTree={setReloadTree}
                ></TreeWithButtons>

            </Space >
            <Spin spinning={roleLoading}>
            <Space className='fullwidth' direction="vertical" style={{padding: "10px", background:"red", width: "100%", background: "#FFF", boxShadow: "0px 0px 4px 1px #EFEFEF"}}>
                <Divider>{rolesId != null && credentials != null ? credentials.name : ""}</Divider>
                <EditRole
                    setRolesId={setRolesId} 
                    rolesId={rolesId} 
                    setBlocked={setBlocked} 
                    blocked={blocked} 
                    treeData={treeData} 
                    setTreeData={setTreeData} 
                    removeNew={removeNew}
                    changeTitleOfNew={changeTitleOfNew}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    parent={parent}
                    setParent={setParent}
                    reloadTree={reloadTree}
                    setReloadTree={setReloadTree}
                ></EditRole>
            </Space >
            </Spin>
        </Space>
        </>
    );
};
export default RolesForm;