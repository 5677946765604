import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
    LogoutOutlined
} from '@ant-design/icons';
import handleLogout from '../../login/components/logoutFunction';
import { Breadcrumb, Layout, Menu, Spin, theme, Image, Space, Flex, Drawer, Tooltip, Button, Avatar, Dropdown } from 'antd';

const AvatarUser = (props) => {

    useEffect(() => {
        // Add a request interceptor
        const requestInterceptor = axios.interceptors.request.use(
          (config) => {
            // Modify config or add headers before the request is sent
            config.headers['Authorization'] = Cookies.get('mgltoken');
            config.headers['App'] = 'BgCVCYYGrajp3ObJyDgXUqXxZ';
            return config;
          },
          (error) => {
            // Do something with request error
            return Promise.reject(error);
          }
        );
    
        // Cleanup the interceptors when the component unmounts
        return () => {
          axios.interceptors.request.eject(requestInterceptor);
        };
      }, []);
  const [user, setUser] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/auth/get/user');
        setUser(response.data)
      } catch (error) {
        console.error('Errord fetchsing sdata:', error);
      }
    };

    fetchData();
  }, [])

  const menu = (
    <Menu>
      <Menu.Item key="delete" onClick={handleLogout} icon={<LogoutOutlined />} danger>
        Wyloguj się
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown trigger={'click'} overlay={menu} placement="bottomRight">
        <Avatar style={{ backgroundColor: "#BD8136", verticalAlign: 'middle' }} size="large">
            {user != null ? user.user.mlg_users_fullname[0]+user.user.mlg_users_fullname[1] : ""}
        </Avatar>
    </Dropdown>

  );
};

export default AvatarUser;
