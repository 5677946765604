import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import axios from 'axios'
import Cookies from 'js-cookie'

export default function Idle() {
  const [state, setState] = useState(1)
  const [count, setCount] = useState(0)
  const [renew, setRenew] = useState(null)
  const [remaining, setRemaining] = useState(0)  

  useEffect(() => {
    setRenew(setInterval(async () => {
        const response = await axios.post('/auth/renew/session');
        Cookies.set('mgltoken', response.data.token, { expires: 7, domain: process.env.REACT_APP_DOMAIN });
    },1000 * 60 * 2))
  }, []);

  useEffect(() => {
  }, [remaining]);

  const onIdle = () => {
    clearTimeout(renew);
    setState(0)
  }

  const onActive = () => {
    setState(1)
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 *  60 * 15,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <>

    </>
  )
}
