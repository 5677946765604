import axios from "axios";
import Cookies from "js-cookie";
const isCan = async (perms) => {
    try {
        const res = await axios.post('/perms/get/by/user', {}, {
            headers: {
                "Authorization": Cookies.get('mgltoken'),
                "App": process.env.REACT_APP_APP_KEY
            }
        });
        const userPerms = res.data;
        const allIncluded = perms.every(perm => userPerms.includes(perm));
        return allIncluded ? true : null
    } catch (error) {
        return null
    }
}

export default isCan;

