import axios from "axios";
import Cookies from "js-cookie";
const handleLogout = async () => {
    try {
      const response = await axios.post('/auth/logout',{
        data: {
            token: Cookies.get('mgltoken')
          },
      });
      Cookies.remove('mgltoken');
      window.location.href = "/"
    } catch (error) {
      console.log(error)
    }
};

export default handleLogout