import { useEffect, useState } from 'react';
import PrivateRoute from '../../../components/privateroute';
import {Router, Route, Routes, Navigate } from 'react-router';
import { Layout, Flex} from 'antd';
import React, { useMemo } from 'react';
import { useNavigate, redirect } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined, CheckOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import {
    Form,
    Header,
    Input,
    Image,
    Tooltip,
    notification 
} from 'antd'
import Button from '../../../../../components/Button'
import { Typography } from 'antd';
import axios from 'axios';
const {Content} = Layout;
const { Title, Text } = Typography;
export default function FormLogin(props) {

    const {setLoggedin} = props
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [loading, setLoading] = useState(false)
    const [icon, setIcon] = useState(<></>)
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        [name]: value,
      }));
    };

    const openErrorNotification = (data) => {
        notification.error({
          message: 'Błędne dane logowania',
          description: data.message + " | CODE: "+ data.code,
          duration: 3
        });
    };

    const handleLogin = async () => {
        try {
          const response = await axios.post('/auth/log/in', {
            credentials
          });
          setLoading(false)
          setIcon(<CheckOutlined />)
          // console.log('mgltoken', response.data.token)
          Cookies.set('mgltoken', response.data.token, { expires: 7, domain: process.env.REACT_APP_DOMAIN });
          setLoggedin(true)
        } catch (error) {
          setLoading(false)
          console.log(error)
          openErrorNotification(error.response.data)
        }
    };

    const onFinish = (values) => {
        setLoading(true)
        setTimeout(function(){handleLogin()}, 700)
      };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };




    return (
        <Form
            name="basic"
            labelCol={{
            span: 24,
            }}
            wrapperCol={{
            span: 32,
            }}
            style={{
            maxWidth: 550,
            }}
            initialValues={{
            remember: true,
            }}
    
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Title level='2'>Logowanie</Title>
            <Text style={{marginBottom: '20px', display: "block"}}>Aby przejść do danej aplikacji zaloguj się poprzez centralny system logowania.</Text>
            <Form.Item>
            <Input name='username' onChange={handleInputChange}
                suffix={
                    <Tooltip title="Wpisz adres e-mail zgodny z domeną aplikacji lub numer telefonu zgodny z wzorem XXX-XXX-XXX">
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                }
            placeholder='Nazwa użytkownika' size='large' style={{width: "100%"}} required/>
            </Form.Item>

            <Form.Item>
            <Input.Password name='password' onChange={handleInputChange} size='large' placeholder='Hasło' style={{width: "100%"}} required/>
            </Form.Item>

            <Form.Item
                style={{display: 'flex', justifyContent: 'right'}}
            >
            <Button icon={icon} loading={loading} size={'large'} type="primary" htmlType="submit">
                Zaloguj się
            </Button>
            </Form.Item>
        </Form>
    );
  };